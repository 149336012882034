<template>
  <div class="switch-student-dialog-body" :class="{'scrolling-style': isScrolling}">
    <div id="students-list-wrapper" class="students-list-wrapper" @scroll="watchScroll">
      <div v-if="switchStuTipText" class="coupon-tip-container">
        <div class="coupon-tip font-medium">
          <i class="iconfont icon-alert" /><span>{{ switchStuTipText }}</span>
        </div>
      </div>
      <div class="students-list-title font-medium">
        <!-- {{ $t('login.switchStudent.phoneNumTip', [userInfo.countryCallingCode, userInfo.phone || encryptPhone]) }} -->
        {{ $t('login.switchStudent.dialogTip') }}
      </div>

      <div class="students-list">
        <template v-if="isLoading">
          <Loading :show="isLoading" :margin-top="0" :margin-bottom="0" />
        </template>
        <template v-else>
          <div v-for="(student, index) in studentsList" :key="index" class="student-item-wrapper" @click="selectStudentHandler(student.uid, student)">
            <StudentItemCard :student-info="student" :can-selected="true" :class="{selected: student.uid === currentSelectedStudentId}">
              <!-- <template v-if="studentsList.length === 1 && !nickNameModified" v-slot:stu-name>
                <span>
                  {{ $t('login.switchStudent.currentStudent') }}
                </span>
              </template> -->
            </StudentItemCard>
          </div>
        </template>
      </div>
      <template v-if="studentsList.length === 1 && !isComleteInfo && !isAppli">
        <div class="guide-to-add-student font-medium">
          {{ $t('login.switchStudent.addTip[3]') }}
        </div>
      </template>
      <template v-else-if="studentsList.length < 6 && studentsList.length >= 1">
        <div class="guide-to-add-student font-medium">
          {{ $t('login.switchStudent.addTip[0]') }}<span class="btn-add-student" @click="addStudentHandler">{{ $t('login.switchStudent.addTip[1]') }}</span>{{ $t('login.switchStudent.addTip[2]') }}
        </div>
      </template>
    </div>

    <div class="bototm-wrapper">
      <button :disabled="isSubmiting" class="button button-normal button-continue" @click="confirmSelectedStudent">
        {{ isAddCart?$t('order.shoppingCart.addToCart'):$t('login.switchStudent.continueSignUp') }}
      </button>
    </div>
  </div>
</template>
<script>
import bus from 'common/bus';
import { oneApi } from 'config/api';
import { setCookies } from 'common/utils';
import Loading from 'components/common/Loading';
import StudentItemCard from 'components/myAccount/StudentItemCard/index.vue';

const throttle = require('lodash/throttle');

export default {
  components: { Loading, StudentItemCard },
  props: {
    isAddCart: {
      default: false,
      type: Boolean,
    },
    switchStuTipText: {
      default: '',
      type: String,
    },
    studentInfo: {
      type: Array,
    },
    isAppli: {
      default: false,
      tyrp: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      isSubmiting: false,
      isScrolling: false,
      studentsList: [],
      currentStudentId: '',
      currentSelectedStudentId: '',
      studentsListBodyScrollTop: 0,
      nickNameModified: true,
      isAppJsInject: false,
      currentSelectedStudentInfo: null,
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.ucenter.user
    },
    isComleteInfo() {
      return this.$store.state.ucenter.isComleteInfo
    },
  },
  watch: {
    studentsListBodyScrollTop(newVal) {
      const that = this;
      setTimeout(() => {
        if (newVal > 0) {
          that.isScrolling = true;
        } else {
          that.isScrolling = false;
        }
      }, 100)
    },
  },
  async mounted() {
    this.isLoading = true;
    this.isAppJsInject = process.client ? !!window.JsInjectionActivity : false;
    await this.getStudentsList();
    if (this.studentsList.length === 1) {
      await this.setNickNameModify()
    }
    this.isLoading = false;

    bus.$on('switchStudent.refresh', (newUid) => {
      this.refresh(newUid)
    })
  },
  methods: {
    watchScroll: throttle(function watchScrollTop() {
      const ele = document.getElementById('students-list-wrapper');
      this.studentsListBodyScrollTop = ele.scrollTop;
    }),
    async refresh(newUid) {
      this.selectStudentHandler(newUid);
      await this.getStudentsList();
    },
    // 选择学员
    selectStudentHandler(uid, studentInfo) {
      this.currentSelectedStudentId = uid;
      this.currentSelectedStudentInfo = studentInfo;
    },
    // 确认已选学员
    async confirmSelectedStudent() {
      if (this.currentSelectedStudentId !== this.currentStudentId) {
        this.handleSwitchStudent();
      } else {
        // 触发报名校验方法
        this.$emit('switch-success', false, this.currentSelectedStudentInfo)
      }
    },
    async handleSwitchStudent() {
      if (this.isSubmiting) return;
      this.isSubmiting = true;
      const data = {
        targetUid: this.currentSelectedStudentId,
      }
      const res = await this.$axios.post(oneApi.switchAccount, data, { rewritePostBody: true });

      if (Number(res.code) !== 0) {
        this.$Toast(res.msg);
        this.isSubmiting = false;
        return
      }
      const resData = res.data || {};
      this.$store.commit('ucenter/SET_USER', resData)

      this.isSubmiting = false;
      this.switchStudentSuccessHandler(resData);
    },
    async switchStudentSuccessHandler(resData) {
      const accessToken = resData.unifiedAccessToken || '';

      if (!accessToken) {
        this.$Toast('Switch Account Failed!');
        return;
      }
      // 设置Cookie
      setCookies('_official_token', accessToken)
      if (this.isAppJsInject) {
        setCookies('_app_token', accessToken)
        this.$bus.$emit('app-v2.switchAccount', resData);
      }
      // this.$Toast('Switched Successfully');

      // 更新用户信息
      await this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } });

      // 神策-用户登录
      this.Sensors.login(this.$store.state.ucenter.user.uid)

      // 重置当前学员Id
      this.currentStudentId = resData.uid;

      // 触发报名校验方法
      this.$emit('switch-success', true, this.currentSelectedStudentInfo)
    },
    addStudentHandler() {
      this.$emit('add-student');
    },
    async getStudentsList() {
      const res = await this.$axios.post(oneApi.getFamilyStudentsList, {}, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        this.isLoading = false;
        this.$Toast(res.msg);
        return
      }
      const resData = res.data || {};
      this.studentsList = resData.associatedAccount || [];
      // 添加当前登录学员
      this.studentsList.unshift(resData.currentAccount);
      this.studentsList.sort((one, next) => one.uid - next.uid)
      // 设置当前学员id
      this.currentStudentId = resData.currentAccount.uid;

      let firstName = ''
      let lastName = ''

      if (this.studentInfo && this.studentInfo.length > 0) {
        this.studentInfo.forEach((e) => {
          if (e.key === 'givenName') {
            firstName = e.value
          }
          if (e.key === 'familyName') {
            lastName = e.value
          }
        })
      }

      this.currentSelectedStudentId = resData.currentAccount.uid;

      this.studentsList.forEach((e) => {
        if (e.firstName === firstName && e.lastName === lastName) {
          this.currentSelectedStudentId = e.uid
        }
      })

      console.log('当前选中2', this.currentSelectedStudentId)

      this.$store.commit('ucenter/SET_ASSOCIATED_ACCOUNTS', resData.associatedAccount.length || 0)
      if (!this.currentSelectedStudentInfo) {
        // eslint-disable-next-line
        this.currentSelectedStudentInfo = this.studentsList.filter(item => item.uid === this.currentSelectedStudentId)[0];
      }
    },
    async setNickNameModify() {
      const res = await this.$axios.post(oneApi.queryUserNickname, {}, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        this.$Toast(res.msg);
      }
      const resData = res.data || {};
      this.nickNameModified = resData.nickNameModified
    },
  },

}
</script>

<style lang="scss" scoped>
.switch-student-dialog-body {
  position: relative;
  height: 496px;

  &.scrolling-style {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: px2vw(60);
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(222, 226, 231, 0.6) 0%, rgba(222, 226, 231, 0) 100%);
      z-index: 1;
    }
  }

  .coupon-tip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: px2vw(20) auto 0;
    padding: px2vw(16) px2vw(40);
    width: px2vw(686);
    font-size: px2vw(24);
    line-height: px2vw(28);
    color: var(--txt-color-link);
    border-radius: px2vw(100000);
    background: #fff;

    i,
    span {
      display: inline-block;
      vertical-align: middle;
    }

    .icon-alert {
      flex-shrink: 0;
      font-size: px2vw(32);
    }

    span {
      margin-left: px2vw(30);
      word-spacing: 0;
      letter-spacing: 0;
      word-break: keep-all;
    }
  }

  .students-list-title,
  .guide-to-add-student {
    font-size: px2vw(28);
    line-height: px2vw(32);
    color: var(--txt-color-lv2);
    // word-break: keep-all;
  }

  .guide-to-add-student {
    margin: px2vw(40) 0 0;

    .btn-add-student {
      color: var(--txt-color-link);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .students-list-wrapper {
    padding: 0 px2vw(32);
    height: px2vw(800);
    overflow: auto;

    .students-list-title {
      margin: px2vw(40) 0 px2vw(24);
    }

    .students-list {
      min-height: 86px;

      /deep/.loading-wrapper {
        height: 86px;
      }
    }

    .student-item-wrapper {
      margin: 2px auto;
      border-radius: 0;
      overflow: hidden;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      &:only-child {
        border-radius: 10px;
      }

      /deep/ .student-item-card-wrapper {
        border: 0;

        &:first-child {
          border-radius: 0;
        }

        &:last-child {
          border-radius: 0;
        }

        &:only-child {
          border-radius: 0;
        }
      }
    }
  }

  .bototm-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f4f6fa;

    .button-continue {
      display: block;
      margin: px2vw(48) auto;
      width: px2vw(686);
      height: px2vw(96);
    }
  }
}
</style>
