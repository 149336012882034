<template>
  <div>
    <BottomPopup v-if="isShow" :show="isShow" :popup-title="dialogTitle" :show-left-icon="showLeftIcon" :show-right-icon="showRightIcon">
      <template v-if="stepStatus === 'SWITCH_STUDENT'" v-slot:right-icon>
        <div class="header-icon-btn-container right-icon-container" @click="closePicker">
          <i class="iconfont icon-close-new" />
        </div>
      </template>
      <template v-if="stepStatus === 'ADD_STUDENT'" v-slot:left-icon>
        <div class="header-icon-btn-container left-icon-container" @click="changeStepStatus('SWITCH_STUDENT')">
          <i class="iconfont icon-arrow-left" />
        </div>
      </template>
      <template slot="custom-body">
        <SwtichStudentTemplate v-if="stepStatus === 'SWITCH_STUDENT'"
                               :student-info="studentInfo" :is-add-cart="isAddCart" :is-appli="isAppli"
                               :switch-stu-tip-text="switchStuTipText" @add-student="changeStepStatus('ADD_STUDENT')"
                               @switch-success="switchSuccessHandler"
        />
        <AddStudentTemplate v-if="stepStatus === 'ADD_STUDENT'" :student-info="studentInfo" @add-success="changeStepStatus('SWITCH_STUDENT')" />
      </template>
    </BottomPopup>
  </div>
</template>
<script>
import AddStudentTemplate from './AddStudentTemplate';
import SwtichStudentTemplate from './SwtichStudentTemplate';
import BottomPopup from 'components/common/BottomPopup/index.vue';

export default {
  components: { BottomPopup, AddStudentTemplate, SwtichStudentTemplate },
  props: {
    isAddCart: {
      default: false,
      type: Boolean,
    },
    switchStuTipText: {
      default: '',
      type: String,
    },
    isAppli: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isShow: false,
      /**
       * stepStatus
       * SWITCH_STUDENT - 切换学员
       * ADD_STUDENT - 新增学员
       */
      stepStatus: 'SWITCH_STUDENT',
      studentInfo: [],
    }
  },
  computed: {
    dialogTitle() {
      return this.stepStatus === 'SWITCH_STUDENT' ? this.$t('login.switchStudent.choose') : this.$t('login.switchStudent.add');
    },
    showLeftIcon() {
      return this.stepStatus === 'ADD_STUDENT';
    },
    showRightIcon() {
      return this.stepStatus === 'SWITCH_STUDENT';
    },
  },
  methods: {
    async openPicker(data) {
      this.stepStatus = 'SWITCH_STUDENT'
      this.isShow = true;
      if (data) {
        this.studentInfo = data
      }
    },
    closePicker() {
      this.isShow = false;
    },
    changeStepStatus(newStepStatus) {
      this.stepStatus = newStepStatus;
    },
    switchSuccessHandler(isSwitch, studentInfo) {
      this.closePicker();
      this.$emit('switch-success', isSwitch, studentInfo)
    },
  },
}
</script>

<style lang="scss" scoped>
.left-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: px2vw(64);
  height: px2vw(64);
  text-align: center;
  border-radius: 50%;
  color: var(--txt-color-lv2);
  background: #f4f6fa;

  .iconfont {
    font-size: px2vw(40);
  }
}

.right-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: px2vw(64);
  height: px2vw(64);
  text-align: center;
  border-radius: 50%;
  color: var(--txt-color-lv2);
  background: #f4f6fa;

  .iconfont {
    font-size: px2vw(40);
  }
}

</style>
