<template>
  <div class="student-item-card-wrapper" :class="{'select-student-card': canSelected}">
    <div class="student-main-container">
      <div class="stu-avatar-container">
        <img v-lazy-load :data-src="studentInfo.avatar" alt="avatar">
      </div>
      <div class="stu-info-container font-medium">
        <div class="stu-name text-overflow-ellipsis">
          <slot name="stu-name">
            {{ easternNameOrderEnabled ? `${studentInfo.lastName || ''}${studentInfo.firstName || ''}` || studentInfo.nickName :
              (studentInfo.firstName || '')+(studentInfo.lastName || '') ? `${studentInfo.firstName || ''} ${studentInfo.lastName || ''}`: studentInfo.nickName }}
          </slot>
        </div>
        <div class="stu-no">
          <span>No.</span><span>{{ studentInfo.card | formatCardNo }}</span>
        </div>
      </div>
    </div>
    <div v-if="!canSelected&&!isEdit" class="btn-change-stu-container" @click="switchStudent">
      {{ $t('page.personalInfo.switch') }}
    </div>
    <div v-if="canSelected" class="btn-select-student">
      <i class="iconfont icon-success" />
    </div>
    <div v-if="!canSelected&&isEdit" class="unbindBtn" @click="unbindStudent" />
  </div>
</template>

<script>
export default {
  filters: {
    formatCardNo(val) {
      if (!val) return ''
      return val.replace(/(.{4})/g, '$1 ')
    },
  },
  props: {
    isEdit: {
      default: false,
      type: Boolean,
    },
    studentInfo: {
      default: () => ({}),
      type: Object,
    },
    canSelected: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      stuCardNoClipboard: null,
    }
  },
  computed: {
    easternNameOrderEnabled() {
      const { easternNameOrderEnabled } = JSON.parse(window.localStorage.getItem('clientOptions')) || {}
      return easternNameOrderEnabled || false
    },
  },
  methods: {
    switchStudent() {
      this.$emit('switchStudent', this.studentInfo);
    },
    unbindStudent() {
      this.$emit('delHandle', this.studentInfo)
    },
  },

}
</script>

<style lang="scss" scoped>
.student-item-card-wrapper {
  position: relative;
  margin-bottom: px2vw(2);
  width: 100%;
  height: px2vw(144);
  background: #fff;

  &:first-child {
    border-radius: 0 0 4px 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  .student-main-container {
    display: flex;
    padding: px2vw(32);

    .stu-avatar-container {
      margin-right: px2vw(24);
      width: px2vw(80);
      height: px2vw(80);
      border-radius: 50%;
      border: px2vw(4) solid #f4f6fa;

      img {
        width: px2vw(80);
        height: px2vw(80);
        border-radius: 50%;
      }
    }

    .stu-info-container {
      .stu-name {
        max-width: px2vw(460);
        margin: px2vw(8) 0 px2vw(12);
        font-size: px2vw(28);
        line-height: px2vw(28);
        color: #222;
        font-weight: 500;
      }

      .stu-no {
        font-size: px2vw(24);
        line-height: px2vw(24);
        color: rgba(34, 34, 34, 0.4);
      }
    }
  }

  .btn-change-stu-container {
    position: absolute;
    top: px2vw(58);
    right: px2vw(32);
    width: px2vw(128);
    height: px2vw(56);
    font-weight: 600;
    text-align: center;
    line-height: px2vw(56);
    color: var(--txt-color-link);
    background: var(--txt-color-link3);
    border-radius: px2vw(56);
    cursor: pointer;
  }

  .unbindBtn {
    background: url('~assets/images/common/unbind.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: px2vw(50);
    right: px2vw(32);
    width: px2vw(64);
    height: px2vw(64);
  }

  .btn-select-student {
    position: absolute;
    top: px2vw(60);
    right: px2vw(32);
    width: px2vw(44);
    height: px2vw(44);
    background: var(--txt-color-link3);
    border-radius: 50%;

    .iconfont.icon-success {
      display: none;
    }
  }

  &.select-student-card {
    cursor: pointer;

    &.selected {
      .btn-select-student {
        background: var(--txt-color-link);

        .iconfont.icon-success {
          display: block;
          margin: px2vw(6) auto;
          width: px2vw(32);
          height: px2vw(32);
          font-size: px2vw(32);
          color: #fff;
        }
      }
    }
  }
}
</style>
